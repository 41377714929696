import EventEmitter2 from "eventemitter2";

export const STATUS_NONE = 0;
export const STATUS_PENDING = 1;
export const STATUS_SUCCESS = 2;
export const STATUS_ERROR_PERMISSION_DENIED = 3;
export const STATUS_ERROR_POSITION_UNAVAILABLE = 4;
export const STATUS_ERROR_TIMEOUT = 5;
export const STATUS_ERROR_UNKNOWN = 6;

const SESSION_STORAGE_KEY = 'scores.ca_geoloc';

class GeoLocation extends EventEmitter2 {
  constructor() {
    super();

    this._available = navigator.geolocation ? true : false;
    this._latitude = null;
    this._longitude = null;
    this._status = STATUS_NONE;

    // restore latitude/longitude from SessionStorage
    const data = sessionStorage.getItem(SESSION_STORAGE_KEY);
    
    if( data ) {
      const [ latitude, longitude ] = data.split(',');

      if( latitude && longitude ) {
        this._latitude = parseFloat(latitude);
        this._longitude = parseFloat(longitude);

        this._status = STATUS_SUCCESS;
      }
    }

    this._onRequestSuccess = this._onRequestSuccess.bind(this);
    this._onRequestError = this._onRequestError.bind(this);
  }

  request() {
    // if GeoLocation service isn't available OR status is already defined
    if( !this._available || this._status !== STATUS_NONE ) return;

    this._status = STATUS_PENDING;
    navigator.geolocation.getCurrentPosition(this._onRequestSuccess, this._onRequestError);
  }

  _onRequestSuccess(position) {
    const { latitude, longitude } = position.coords;

    // bail early if returned value hasn't changed
    if( latitude === this._latitude && longitude === this._longitude ) return;

    // save latitude/longitude
    this._latitude = latitude;
    this._longitude = longitude;

    // Save data to sessionStorage
    sessionStorage.setItem(SESSION_STORAGE_KEY, `${this._latitude},${this._longitude}`);

    // update status
    this._status = STATUS_SUCCESS;

    // emit event
    this.emit('change');
  }
  _onRequestError(error) {
    switch(error.code) {
      case error.PERMISSION_DENIED: this._status = STATUS_ERROR_PERMISSION_DENIED; break;
      case error.POSITION_UNAVAILABLE: this._status = STATUS_ERROR_POSITION_UNAVAILABLE; break;
      case error.TIMEOUT: this._status = STATUS_ERROR_TIMEOUT; break;
      default: this._status = STATUS_ERROR_UNKNOWN;
    }
  }

  // getter
  get latitude() { return this._latitude; }
  get longitude() { return this._longitude; }
  get position() { return { lat: this._latitude, lng: this._longitude}; }
  get status() { return this._status; }
}

const SINGLETON = new GeoLocation();
export default SINGLETON;
