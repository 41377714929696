import EventEmitter2 from "eventemitter2";

const STORAGE_KEY = "scores.ca_favoriteRestaurant";

class FavoriteRestaurant extends EventEmitter2 {
  constructor() {
    super();

    this.menu_url = null;
    this._ID = localStorage.getItem(STORAGE_KEY);
  }

  // getter - setter
  get ID() { return this._ID; }
  set ID(value) {
    if( value === this._ID ) return;

    this._ID = value;
    
    localStorage.setItem(STORAGE_KEY, this._ID);
    this.emit('change');
  }
}

const SINGLETON = new FavoriteRestaurant();
export default SINGLETON;
